import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack } from '@mui/material';
import DashboardLayout from '../../components/layouts/Dashboard/Dashboard';
import RecordDetailHeader from '../../components/atoms/RecordDetailHeader/RecordDetailHeader';
import RecordDetailAttribute from '../../components/atoms/RecordDetailAttribute/RecordDetailAttribute';
import { withCommonTools } from '../../components/compounds/CommonWrapper/withCommonTools';
import type { WrappedProps } from '../../components/compounds/CommonWrapper/withCommonTools';
import { useFetchInvoice } from '../../hooks/fetchInvoice';

interface IProps extends WrappedProps {};

function ShowInvoice({ getConfig }: IProps) {
  const { id: invoiceId } = useParams();

  const { data: invoice } = useFetchInvoice(getConfig, invoiceId ?? '');

  if (!invoice) {
    return <DashboardLayout loading />;
  }

  return (
    <DashboardLayout>
      <Stack>
        <RecordDetailHeader
          title="Invoice Details"
          description=""
        >
          {invoice.id && (
            <Button variant='contained' color='primary'>
              Delete
            </Button>
          )
          }
        </RecordDetailHeader>
        <Box sx={{ marginX: 5 }}>
          <RecordDetailAttribute attribute="Id" value={invoice.id.toString()} />
          <RecordDetailAttribute attribute="Total" value={invoice.payerTotal.toString()} />
          <RecordDetailAttribute attribute="Invoice Number" value={invoice.invoiceNumber.toString()} />
        </Box>
      </Stack>
    </DashboardLayout>
  );
}

export default withCommonTools(ShowInvoice);

import React, { useState } from 'react';
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import PaperWithHeader from '../PaperWithHeader/PaperWithHeader';
import { useFetchWaivers } from '../../../hooks/fetchWaiver';
import { withCommonTools } from '../CommonWrapper/withCommonTools';
import type { WrappedProps } from '../CommonWrapper/withCommonTools';
import toProperCase from '../../../utils/strings';
import DrawIcon from '@mui/icons-material/Draw';
import { green, grey } from '@mui/material/colors';
import { useFetchWaiverEnabled } from '../../../hooks/fetchSigningTemplate';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { API_V3_ALLIGATOR_URLS } from '../../../constants/api-urls';

interface IProps extends WrappedProps {
  tripId: number;
};

function BookingTodoListCard({ tripId, getConfig, postConfig }: IProps) {
  const { data: userWaiverEnabled, isLoading } = useFetchWaiverEnabled(getConfig);
  const { data: waivers, refetch } = useFetchWaivers(getConfig, tripId);
  const [sending, setSending] = useState(false);

  const sendWaiver = (e: any) => {
    e.preventDefault();
    const params = {
      signing_document: {
        trip_id: tripId,
      }
    }
    axios.post(API_V3_ALLIGATOR_URLS.signingDocuments.create, params, postConfig).then(() => {
      refetch().catch((e) => { console.log(e) });
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setSending(false);
    });
  }

  if (isLoading) {
    return (<></>);
  }

  if (!userWaiverEnabled) {
    return (<></>);
  }

  return (
    <PaperWithHeader headerTitle='Trip Todos'>
      
      <List sx={{ paddingY: 3, paddingX: 2 }}>
        {waivers && waivers.length > 0 ? waivers.map((waiver) => {
          const itemColor = waiver.status === 'signed' ? green[500] : grey[500];
          return (
            <ListItem key={waiver.id}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: itemColor }}>
                  <DrawIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Trip Waiver"
                secondary={toProperCase(waiver.status ?? '')}
              />
            </ListItem>
          )
        }) : 
          <ListItem
            secondaryAction={
              <Button variant='outlined' startIcon={<SendIcon />} disabled={sending} onClick={(e) => { sendWaiver(e) }}>
                Send
              </Button>
            }
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: grey[500] }}>
                <DrawIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Trip Waiver"
            />
          </ListItem>
        }
      </List>
    </PaperWithHeader>
  );
}

export default withCommonTools(BookingTodoListCard);
import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from '../components/compounds/Auth/PrivateRoute';

import { ROUTER_URLS } from '../constants/router-urls';
import Bookings from '../pages/booking/index';
import Calendar from '../pages/booking/calendar';
import LogIn from '../pages/auth/login';
import Home from '../pages/home';
import Listings from '../pages/listing/index';
import Messages from '../pages/message/index';
import NewListing from '../pages/listing/new';
import UserProfile from '../pages/user/profile';
import UserEditAccount from '../pages/user/editAccount';
import UserEditEmergencyContact from '../pages/user/editEmergencyContact';
import UserEditPassword from '../pages/user/editPassword';
import UserEditProfile from '../pages/user/editProfile';
import UserConnectClose from '../pages/user/connectClose';
import UserConnectRefresh from '../pages/user/connectRefresh';
import QuickCheckout from '../pages/quickCheckout';
import RecentDepositsReport from '../pages/reports/recentDeposits';
import EditListingAddress from '../pages/listing/editAddress';
import EditListingAdditionalDetails from '../pages/listing/editAdditionalDetails';
import EditListingPricing from '../pages/listing/editPricing';
import EditListingFileUploads from '../pages/listing/editFileUploads';
import EditListingAvailability from '../pages/listing/editAvailability';
import EditListing from '../pages/listing/edit';
import ShowListing from '../pages/listing/show';
import Landings from '../pages/landing/index';
import EditLanding from '../pages/landing/edit';
import ShowBooking from '../pages/booking/show';
import ConfirmBooking from '../pages/booking/confirm';
import Invoices from '../pages/invoices/index';
import Invoice from '../pages/invoices/show';

import ReferralProgramDashboard from '../pages/referral-program/dashboard';

import AdminUsers from '../pages/admin/users/index';
import AdminUser from '../pages/admin/users/show';
import AdminUserChangePassword from '../pages/admin/users/editPassword';
import AdminUserCreateHost from '../pages/admin/users/createHost';
import AdminBookings from '../pages/admin/bookings/index';
import AdminListings from '../pages/admin/listings/index';
import AdminListing from '../pages/admin/listings/show';
import AdminEditListing from '../pages/admin/listings/edit';
import AdminEditAdditionalChargeListing from '../pages/admin/listings/editAdditionalCharges';
import AdminEditAddressListing from '../pages/admin/listings/editAddress';
import AdminEditAvailabilityListing from '../pages/admin/listings/editAvailability';
import AdminEditDetailsListing from '../pages/admin/listings/editDetails';
import AdminEditPhotosListing from '../pages/admin/listings/editPhotos';
import AdminEditPricingListing from '../pages/admin/listings/editPricing';
import AdminNewListing from '../pages/admin/listings/new';
import AdminEditAdminFieldsListing from '../pages/admin/listings/editAdminFields';

import AdminDebugBooking from '../pages/admin/bookings/debug';
import AdminEditBooking from '../pages/admin/bookings/edit';
import AdminOfflineBooking from '../pages/admin/bookings/offline';
import AdminConfirmOfflineBooking from '../pages/admin/bookings/confirmOffline';
import AdminHostContacts from '../pages/admin/hostContacts/index';
import AdminPayments from '../pages/admin/payments/index';
import AdminEditPayment from '../pages/admin/payments/edit';
import AdminPayouts from '../pages/admin/payouts/index';
import AdminEditPayout from '../pages/admin/payouts/edit';
import AdminRefunds from '../pages/admin/refunds/index';
import AdminNewRefund from '../pages/admin/refunds/new';
import AdminReversals from '../pages/admin/reversals/index';
import AdminNewReversal from '../pages/admin/reversals/new';
import AdminInvoices from '../pages/admin/invoices/index';
import AdminInvoice from '../pages/admin/invoices/show';

import AdminUserEditProfile from '../pages/admin/users/editProfile';
import AdminUserEditEmergencyContact from '../pages/admin/users/editEmergencyContact';
import AdminUserEditUsageFees from '../pages/admin/users/editUsageFees';
import Message from '../pages/message/show';
import ForgotPassword from '../pages/auth/forgotPassword';
import ListingViewsReport from '../pages/reports/listingViews';
import ResetPassword from '../pages/auth/resetPassword';
import ConfirmEmail from '../pages/auth/confirmEmail';
import HappyErrorBoundary from '../components/compounds/HappyErrorBoundary/HappyErrorBoundary';
import Waivers from '../pages/waivers/index';
import WaiversTemplates from '../pages/waivers/templates';
import WaiverUploads from '../pages/waivers/waiverUploads';


export const ROUTES_CONFIG = [
  {
    path: ROUTER_URLS.auth.login,
    element: <LogIn />,
    errorElement: <HappyErrorBoundary />,
  },
  {
    path: ROUTER_URLS.user.forgotPassword,
    element: <ForgotPassword />,
    errorElement: <HappyErrorBoundary />,
  },
  {
    path: ROUTER_URLS.user.resetPassword,
    element: <ResetPassword />,
    errorElement: <HappyErrorBoundary />,
  },
  {
    path: ROUTER_URLS.user.verifyEmail,
    element: <ConfirmEmail />,
    errorElement: <HappyErrorBoundary />,
  },
  {
    element: <PrivateRoute rolesRequired={['admin', 'host', 'referral', 'hostAndReferral', 'sales']} />,
    children: [
      {
        path: ROUTER_URLS.user.profile,
        element: <UserProfile />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.user.editAccount,
        element: <UserEditAccount />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.user.editEmergencyContact,
        element: <UserEditEmergencyContact />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.user.editPassword,
        element: <UserEditPassword />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.user.edit,
        element: <UserEditProfile />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.user.connectClose,
        element: <UserConnectClose />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.user.connectRefresh,
        element: <UserConnectRefresh />,
        errorElement: <HappyErrorBoundary />,
      },
    ]
  },
  {
    element: <PrivateRoute rolesRequired={['admin', 'host', 'hostAndReferral']} />,
    children: [
      {
        path: ROUTER_URLS.bookings.index,
        element: <Bookings />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.bookings.show,
        element: <ShowBooking />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.bookings.confirm,
        element: <ConfirmBooking />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.calendar,
        element: <Calendar />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.messages.index,
        element: <Messages />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.messages.show,
        element: <Message />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.index,
        element: <Listings />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.show,
        element: <ShowListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.new,
        element: <NewListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.edit,
        element: <EditListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.editAddress,
        element: <EditListingAddress />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.editAdditionalDetails,
        element: <EditListingAdditionalDetails />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.editPricing,
        element: <EditListingPricing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.editFileUploads,
        element: <EditListingFileUploads />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.listings.editAvailability,
        element: <EditListingAvailability />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.landings.index,
        element: <Landings />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.landings.edit,
        element: <EditLanding />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.reports.recentDeposits,
        element: <RecentDepositsReport />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.quickCheckout,
        element: <QuickCheckout />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.reports.listingViews,
        element: <ListingViewsReport />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.home,
        element: <Home />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.waivers.index,
        element: <Waivers />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.waivers.waiverUpload,
        element: <WaiverUploads />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.waivers.templates,
        element: <WaiversTemplates />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.invoices.index,
        element: <Invoices />,
      },
      {
        path: ROUTER_URLS.invoices.show,
        element: <Invoice />,
      },
    ],
  },
  {
    element: <PrivateRoute rolesRequired={['admin', 'referral', 'hostAndReferral', 'sales']} />,
    children: [
      {
        path: ROUTER_URLS.referralProgram.dashboard,
        element: <ReferralProgramDashboard />,
        errorElement: <HappyErrorBoundary />,
      }
    ]
  },
  {
    element: <PrivateRoute rolesRequired={['admin', 'sales']} />,
    children: [
      {
        path: ROUTER_URLS.admin.users.index,
        element: <AdminUsers />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.users.show,
        element: <AdminUser />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.bookings.index,
        element: <AdminBookings />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.index,
        element: <AdminListings />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.show,
        element: <AdminListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.edit,
        element: <AdminEditListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editAdditionalCharges,
        element: <AdminEditAdditionalChargeListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editAddress,
        element: <AdminEditAddressListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editAvailability,
        element: <AdminEditAvailabilityListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editDetails,
        element: <AdminEditDetailsListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editPhotos,
        element: <AdminEditPhotosListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editPricing,
        element: <AdminEditPricingListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.new,
        element: <AdminNewListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.listings.editAdminFields,
        element: <AdminEditAdminFieldsListing />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.bookings.debug,
        element: <AdminDebugBooking />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.contactHosts.index,
        element: <AdminHostContacts />,
        errorElement: <HappyErrorBoundary />,
      },
    ]
  },
  {
    element: <PrivateRoute rolesRequired={['admin']} />,
    children: [
      {
        path: ROUTER_URLS.admin.users.editPassword,
        element: <AdminUserChangePassword />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.users.new,
        element: <AdminUserCreateHost />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.bookings.edit,
        element: <AdminEditBooking />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.bookings.offline,
        element: <AdminOfflineBooking />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.bookings.confirmOffline,
        element: <AdminConfirmOfflineBooking />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.payments.index,
        element: <AdminPayments />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.payments.edit,
        element: <AdminEditPayment />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.payouts.index,
        element: <AdminPayouts />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.payouts.edit,
        element: <AdminEditPayout />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.refunds.index,
        element: <AdminRefunds />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.refunds.new,
        element: <AdminNewRefund />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.reversals.index,
        element: <AdminReversals />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.reversals.new,
        element: <AdminNewReversal />,
        errorElement: <HappyErrorBoundary />,
      },
      {
        path: ROUTER_URLS.admin.users.edit,
        element: <AdminUserEditProfile />,
      },
      {
        path: ROUTER_URLS.admin.users.editEmergencyContact,
        element: <AdminUserEditEmergencyContact />,
      },
      {
        path: ROUTER_URLS.admin.users.editUsageFees,
        element: <AdminUserEditUsageFees />,
      },
      {
        path: ROUTER_URLS.admin.invoices.index,
        element: <AdminInvoices />,
      },
      {
        path: ROUTER_URLS.admin.invoices.show,
        element: <AdminInvoice />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/404" replace />,
  },
];

export const ROUTES = createBrowserRouter(ROUTES_CONFIG);
